import React, { useState } from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { Delete, Folder } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { filter, map } from "lodash";

import RemoveViewModal from "../RemoveViewModal/RemoveViewModal.component.jsx";
import { setActiveView } from "../../slices/views.slicer.js";
import { ORIGINAL_SOURCE } from "../../constant/common.js";
import { setActiveGoogleView } from "../../slices/googleViews.slicer.js";
import { MAPPED_SOURCE_TO_VIEWS } from "../../utils/index.js";

export const MenuButton = ({ folderId, name, source = ORIGINAL_SOURCE.FACEBOOK }) => {
  const { list } = useSelector((state) => state[MAPPED_SOURCE_TO_VIEWS(source)]);
  const views = filter(list, { folderId });
  const [pickedView, setPickedView] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const onClickRemoveIconHandler = (view) => () => {
    setOpenModal(true);
    setPickedView(view);
  };
  const onClickCancelIconHandler = () => setOpenModal(false);

  const handleMoreClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMoreClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();
  // set the active view handle on click of a view
  const handleViewClick = (_id) => {
    switch (source) {
      case ORIGINAL_SOURCE.FACEBOOK:
        dispatch(setActiveView(_id));
        break;
      case ORIGINAL_SOURCE.GOOGLE:
        dispatch(setActiveGoogleView(_id));
        break;
      default:
        dispatch(setActiveView(_id));
        break;
    }
  };

  return (
    <>
      <IconButton onClick={handleMoreClick}>
        <Folder />
        {name}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMoreClose}
      >
        {map(views, (view) => (
          <MenuItem key={view._id} onClick={() => handleViewClick(view._id)}>
            {view.name}
            <IconButton onClick={onClickRemoveIconHandler(view)}>
              <Delete />
            </IconButton>
          </MenuItem>
        ))}
        {pickedView && (
          <RemoveViewModal
            view={pickedView}
            openModal={openModal}
            setOpenModal={setOpenModal}
            onClickCancelIconHandler={onClickCancelIconHandler}
            source={source}
          />
        )}
      </Menu>
    </>
  );
};

export default MenuButton;
