import React from "react";
import moment from "moment-timezone";
import { DesktopDateRangePicker } from "@mui/x-date-pickers-pro/DesktopDateRangePicker";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { compact } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import { changeDate } from "../../slices/metrics.slicer";
import { ORIGINAL_SOURCE } from "../../constant";
import { changeGoogleDate } from "../../slices/googleMetrics.slicer";

moment.tz.setDefault("America/Los_Angeles");

const DateRangePickerCalendarProp = ({ source = ORIGINAL_SOURCE.FACEBOOK }) => {
  const dispatch = useDispatch();
  const { startPoint, endPoint } = useSelector((state) => state[source]);

  const onChangeHandler = (value, context) => {
    if (compact(context.validationError).length || compact(value).length < 2) {
      console.log(context.validationError);
    } else {
      switch (source) {
        case ORIGINAL_SOURCE.FACEBOOK:
          dispatch(changeDate(value));
          break;
        case ORIGINAL_SOURCE.GOOGLE:
          dispatch(changeGoogleDate(value));
          break;
        default:
          dispatch(changeDate(value));
          break;
      }
    }
  };
  const shortcutsItems = [
    {
      label: "Today",
      getValue: () => {
        return [moment(), moment()];
      },
    },
    {
      label: "Yesterday",
      getValue: () => {
        return [moment().subtract(1, "days"), moment().subtract(1, "days")];
      },
    },
    {
      label: "Last 3 Days",
      getValue: () => {
        return [moment().subtract(3, "days"), moment().subtract(1, "days")];
      },
    },
    {
      label: "Last 5 Days",
      getValue: () => {
        return [moment().subtract(5, "days"), moment().subtract(1, "days")];
      },
    },
    {
      label: "Last 7 Days",
      getValue: () => {
        return [moment().subtract(7, "days"), moment().subtract(1, "days")];
      },
    },
  ];
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DemoContainer
        components={["SingleInputDateRangeField", "DesktopDateRangePicker"]}
      >
        <DemoItem component="DesktopDateRangePicker">
          <DesktopDateRangePicker
            defaultValue={[moment(startPoint), moment(endPoint)]}
            calendars={2}
            slots={{ field: SingleInputDateRangeField }}
            onChange={onChangeHandler}
            sx={{ minWidth: 200 }}
            slotProps={{
              shortcuts: {
                items: shortcutsItems,
              },
              actionBar: { actions: [] },
            }}
          />
        </DemoItem>
      </DemoContainer>
    </LocalizationProvider>
  );
};
export default DateRangePickerCalendarProp;
