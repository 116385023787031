import React, { useState } from "react";
import moment from "moment-timezone";
import {
  useGridApiContext,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
  gridExpandedSortedRowEntriesSelector,
} from "@mui/x-data-grid-pro";

import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Checkbox,
  Button,
  IconButton,
  FormControlLabel,
} from "@mui/material";
import { isEmpty, map } from "lodash";
import ViewMenu from "../ViewList/ViewMenu.component.jsx";

import { Save } from "@mui/icons-material";

import UpdateCampModal from "./Modals/UpdateCampBudgetModal/UpdateCampaignModal.jsx";
import { filterUpdateGoogleView, getGoogleViewsAction, insertGoogleViewAction, resetGoogleView, updateGoogleSelectedRows } from "../../slices/googleViews.slicer.js";
import { updateGoogleSummaryMetrics } from "../../slices/googleMetrics.slicer.js";
import { ORIGINAL_SOURCE } from "../../constant/common.js";

const { useEffect } = React;

const folders = [
  {
    id: 5,
    name: "PFH",
  },
  {
    id: 6,
    name: "Politics",
  },
  {
    id: 7,
    name: "New stores",
  },
  {
    id: 8,
    name: "DG",
  },
];

// set timezone to America/Los_Angeles
moment.tz.setDefault("America/Los_Angeles");

const CustomGoogleToolbar = () => {
  const dispatch = useDispatch();
  const {
    activeView,
    list: views,
    selectedRows,
  } = useSelector((state) => state.googleViews);
  const { writable, username } = useSelector((state) => state.auth);
  const apiRef = useGridApiContext();
  useEffect(() => {
    dispatch(
      updateGoogleSummaryMetrics(gridExpandedSortedRowEntriesSelector(apiRef))
    );
    dispatch(filterUpdateGoogleView(gridExpandedSortedRowEntriesSelector(apiRef)));
  }, [gridExpandedSortedRowEntriesSelector(apiRef)]);

  const ViewMenus = map(folders, (folder) => (
    <ViewMenu key={folder.id} folderId={folder.id} name={folder.name} source={ORIGINAL_SOURCE.GOOGLE}/>
  ));

  // fetch the views
  useEffect(() => {
    dispatch(getGoogleViewsAction());
  }, []);
  // if activeView changes, load the view
  useEffect(() => {
    if (activeView) {
      // find the matching view in the list
      const view = views.find((view) => view._id === activeView);
      console.log(view);
      apiRef.current.restoreState(view);
      if (!isEmpty(view.selectedRows)) {
        dispatch(updateGoogleSelectedRows(view.selectedRows));
      }
    }
  }, [activeView]);

  // reset the view
  const resetViewHandler = () => {
    apiRef.current.setFilterModel({ items: [] }); // Clear grid filters
    dispatch(resetGoogleView());
  };
  const [openDialog, setOpenDialog] = useState(false);
  const [useSelectedRows, setUseSelectedRows] = useState(false);
  const [openModalUpdateBudget, setOpenModalUpdateBudget] = useState(false);
  const [folderId, setFolderId] = useState("");
  const [viewName, setViewName] = useState("");
  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };
  const handleFolderChange = (event) => {
    setFolderId(event.target.value);
  };

  const handleNameChange = (event) => {
    setViewName(event.target.value);
  };
  const onCreateView = () => {
    console.log({ useSelectedRows });
    const view = {
      name: viewName,
      folderId: folderId,
      ...apiRef.current.exportState(),
      ...(useSelectedRows && { selectedRows }),
      feView: "google",
    };
    console.log(view);
    dispatch(insertGoogleViewAction(view));
    setOpenDialog(false);
  };

  return (
    <>
      <GridToolbarContainer>
        <Button
          variant="contained"
          disabled={!activeView}
          onClick={resetViewHandler}
          color="success"
          size="small"
        >
          Reset View
        </Button>
        {ViewMenus}
        <IconButton onClick={handleDialogOpen}>
          <Save />
        </IconButton>
        <Dialog
          open={openDialog}
          onClose={handleDialogClose}
          maxWidth="md"
          sx={{ "& .MuiDialogContent-root": { minWidth: 600 } }}
        >
          <DialogTitle>Create View</DialogTitle>
          <DialogContent>
            <TextField
              sx={{ width: "30%" }}
              select
              label="Folder"
              onChange={handleFolderChange}
              value={folderId}
            >
              {map(folders, (folder) => (
                <MenuItem key={folder.id} value={folder.id}>
                  {folder.name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              sx={{ width: "70%" }}
              label="View Name"
              onChange={handleNameChange}
              value={viewName}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={useSelectedRows}
                  onChange={() => setUseSelectedRows(!useSelectedRows)}
                />
              }
              label="Use Selected Rows?"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose}>Cancel</Button>
            <Button onClick={onCreateView}>Create</Button>
          </DialogActions>
        </Dialog>
        <GridToolbarFilterButton />
        <GridToolbarColumnsButton />
        {writable && (
          <Button
            variant="contained"
            disabled={isEmpty(selectedRows)}
            onClick={() => setOpenModalUpdateBudget(true)}
            color="warning"
            size="small"
          >
            Chỉnh camps
          </Button>
        )}
        {!isEmpty(selectedRows) && writable && (
          <UpdateCampModal
            openModal={openModalUpdateBudget}
            setOpenModal={setOpenModalUpdateBudget}
            onClickCancelIconHandler={() => setOpenModalUpdateBudget(false)}
            row={selectedRows[0]}
          />
        )}
      </GridToolbarContainer>
    </>
  );
};

export default CustomGoogleToolbar;
