import React from "react";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";

import { useDispatch } from "react-redux";
import { deleteViewAction as removeView } from "../../slices/views.slicer.js";
import { ORIGINAL_SOURCE } from "../../constant/common.js";
import { removeGoogleViewsAction as removeGoogleView } from "../../slices/googleViews.slicer.js";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const RemoveViewModal = ({
  view,
  openModal,
  setOpenModal,
  onClickCancelIconHandler,
  source = ORIGINAL_SOURCE.FACEBOOK
}) => {
  const dispatch = useDispatch();
  const onConfirmHandler = () => {
    switch (source) {
      case ORIGINAL_SOURCE.FACEBOOK:
        dispatch(removeView(view._id));
        break;
      case ORIGINAL_SOURCE.GOOGLE:
        dispatch(removeGoogleView(view._id));
        break;
      default:
        dispatch(removeView(view._id));
        break;
    }
    setOpenModal(false);
  };
  const { name } = view;
  return (
    <Modal
      open={openModal}
      onBackdropClick={() => setOpenModal(false)}
      onClose={onClickCancelIconHandler}
    >
      <Box sx={style}>
        <h2 id="child-modal-title">{`Removing ${name}`}</h2>
        <p id="child-modal-description">
          Check kỹ coi có đúng là muốn xóa view này không nhé!
        </p>
        <Button onClick={onClickCancelIconHandler}>Mình nhầm!</Button>
        <Button onClick={onConfirmHandler}>Confirm</Button>
      </Box>
    </Modal>
  );
};

RemoveViewModal.propTypes = {
  view: PropTypes.object.isRequired,
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  onClickCancelIconHandler: PropTypes.func.isRequired,
};

export default RemoveViewModal;
