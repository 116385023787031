import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import moment from "moment-timezone";
import { map, orderBy, random, sumBy } from "lodash";
import { toast } from "react-toastify";
import { getGoogleMetrics } from "../services/metrics";
import { updateGoogleCampaign } from "../services/campaigns";

moment.tz.setDefault("America/Los_Angeles");

const initialState = {
  isLoading: false,
  totalOrders: 0,
  list: [],
  summaryMetrics: {
    id: "summary-metrics",
    spend: 0,
    revenue: 0,
    orders: 0,
    clicks: 0,
    impressions: 0,
    adsRev: 500,
    roas: 0,
    cr: 0,
    cpc: 0,
    budgetB: 0,
  },
  campTypesInfo: [],
  startPoint: moment().format("YYYY-MM-DD"),
  endPoint: moment().format("YYYY-MM-DD"),
};

export const getGoogleMetricsAction = createAsyncThunk(
  "google_metrics/get_all",
  async ({ start, end, level }) => getGoogleMetrics({ start, end, level })
);

export const updateGoogleCampaignAction = createAsyncThunk(
  "google_campaigns/update",
  async ({ campaignId, data }) => updateGoogleCampaign({ campaignId, data })
);

const googleMetricsSlice = createSlice({
  name: "google_metrics",
  initialState,
  reducers: {
    changeGoogleDate(state, action) {
      const [start, end] = action.payload;
      state.startPoint = moment(start).format("YYYY-MM-DD");
      state.endPoint = moment(end).format("YYYY-MM-DD");
    },
    updateGoogleSummaryMetrics(state, action) {
      const { payload } = action;

      const allData = map(payload, "model");
      const id =
        current(state).summaryMetrics[0]?.id ||
        `abdsjjs__2j34h-${random(1, 1000)}`;
      const totalOrders = sumBy(allData, "orders");
      const totalRevenue = sumBy(allData, "revenue");

      const totalSpend = sumBy(allData, "cost");
      const totalClicks = sumBy(allData, "clicks");
      const totalCR = !totalClicks ? 0 : (totalOrders / totalClicks) * 100;
      const totalCpc = !totalClicks ? 0 : totalSpend / totalClicks;
      const totalRoas = !totalSpend ? 0 : totalRevenue / totalSpend;
      const totalAdsRev = !totalRevenue ? 0 : (totalSpend / totalRevenue) * 100;

      const newSummaryMetrics = {
        spend: totalSpend,
        orders: totalOrders,
        clicks: totalClicks,
        adsRev: totalAdsRev,
        roas: totalRoas,
        cr: totalCR,
        cpc: totalCpc,
        id,
      };
      state.summaryMetrics = newSummaryMetrics;
    },
  },
  extraReducers: {
    [getGoogleMetricsAction.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getGoogleMetricsAction.rejected]: (state, action) => {
      state.isLoading = false;
      toast.error("Get Google Metrics failed");
    },
    [getGoogleMetricsAction.fulfilled]: (state, action) => {
      // FOR TESTING ONLY
      // const { metrics, summaryMetrics, campTypesInfo, totalOrders } =
      //   GOOGLE_METRICS;
      const { metrics, summaryMetrics, campTypesInfo, totalOrders } =
        action.payload;
      const refactoredMetrics = map(metrics, (i) => ({
        ...i,
        created_time: i.created_time || "",
        initBudget: i.initBudget || undefined,
      }));

      state.list = refactoredMetrics;
      state.summaryMetrics = {
        ...summaryMetrics,
        id: "summary-metrics",
      };
      state.campTypesInfo = orderBy(
        campTypesInfo,
        "campTypeTotalSpend",
        "desc"
      );
      state.totalOrders = totalOrders;
      state.isLoading = false;
      toast.success("Get Google Metrics Successfully");
    },
    [updateGoogleCampaignAction.pending]: (state, action) => {
      state.isLoading = true;
    },
    [updateGoogleCampaignAction.rejected]: (state, action) => {
      state.isLoading = false;
      toast.error("Update Google Campaign Failed");
    },
    [updateGoogleCampaignAction.fulfilled]: (state, action) => {
      const { payload } = action;
      const { list } = state;
      const { campaignId, success, data } = payload;
      const { targetBudget, targetRoas } = data?.payload;
      if (!success) {
        state.list = list;
        state.isLoading = false;
        toast.error("Update Google Campaign Failed");
      } else {
        const newList = map(list, (i) => {
          if (i.id !== campaignId) {
            return i;
          }
          console.log(i);
          return {
            ...i,
            ...(targetBudget && { campaignBudget: targetBudget }),
            ...(targetRoas && { targetRoas }),
          };
        });
        state.list = newList;
        state.isLoading = false;
        toast.success("Update campaign successfully");
      }
    },
  },
});

export const { changeGoogleDate, updateGoogleSummaryMetrics } =
  googleMetricsSlice.actions;

export default googleMetricsSlice.reducer;
