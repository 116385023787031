import { ORIGINAL_SOURCE } from "../constant";

export const MAPPED_SOURCE_TO_VIEWS = (source) => {
  switch (source) {
    case ORIGINAL_SOURCE.FACEBOOK:
      return "views";
    case ORIGINAL_SOURCE.GOOGLE:
      return "googleViews";
    default:
      return "views";
  }
};
export const kFormatter = (num) => {
  return Math.abs(num) > 999
    ? (Math.sign(num) * Math.round(Math.abs(num) / 100)) / 10 + "k"
    : (Math.sign(num) * Math.abs(num)).toFixed(0);
};
